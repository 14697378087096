import React from "react"
// import Img from "gatsby-image"
import { graphql } from "gatsby"
import style from "./services.module.css"
import styled from "./page.module.css"
import Navbar from "../components/Navbar"
import Footer from "../components/footer"

const CrossBrowser= ({data}) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return ( 
  <>
     <Navbar/>
    <header>
      <div className={style.p}>
      <p className={style.heading}>"Let's face it, this is the 'bane' of all web based product development. <br></br> Let Prueba ease your pain."</p>
      </div>
      </header>
      <div className={styled.content} >
      <h2>{frontmatter.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: html }}/> 
      </div>
      <Footer />
      
      </>
      
  )
}
export const query = graphql`
query CrossBrowser {
    markdownRemark(fileAbsolutePath: {regex: "/CrossBrowserTesting.md/"}) {
        html
        frontmatter {
          title
        }
      }
      file(name: { eq: "prueba_cross_browser_testing_detail" }, extension: { eq: "png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, pngQuality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `

export default CrossBrowser